import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {usePageActions, usePageStateSelector} from '../../../../../hooks/state'
import {ticketDescriptionExpanded} from '../../../../../selectors/tickets-picker'
import {ExpansionButton} from '../expansion-button'
import {TicketDescriptionProps} from './interfaces'
import {classes} from './ticket-description.st.css'

export const TicketDescription: React.FC<TicketDescriptionProps> = ({ticket, className}) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const expanded = usePageStateSelector(state => ticketDescriptionExpanded(state, ticket.id))
  const {expandDescription, collapseDescription} = usePageActions()
  const {experiments} = useExperiments()
  const ticketMoreInfoButtonSettingEnabled = experiments.enabled(ExperimentNames.TicketMoreInfoButtonSetting)

  if (!ticket.description) {
    return null
  }

  return (
    <div className={classNames(classes.container, className, {[classes.alignment]: !isMobile})}>
      {ticketMoreInfoButtonSettingEnabled ? (
        <div className={classes.ticketMoreInfoButton}>
          <ExpansionButton
            expanded={expanded}
            className={classNames({[classes.mobileLink]: isMobile, [classes.expandedLink]: !isMobile && expanded})}
            dataHook={DH.MORE_BUTTON}
            onChange={value => {
              value ? expandDescription(ticket.id) : collapseDescription(ticket.id)
            }}
          >
            {t('ticketsPicker.ticketDescription.expandButton')}
          </ExpansionButton>
        </div>
      ) : (
        <ExpansionButton
          expanded={expanded}
          className={classNames({[classes.mobileLink]: isMobile, [classes.expandedLink]: !isMobile && expanded})}
          dataHook={DH.MORE_BUTTON}
          onChange={value => {
            value ? expandDescription(ticket.id) : collapseDescription(ticket.id)
          }}
        >
          {t('ticketsPicker.ticketDescription.expandButton')}
        </ExpansionButton>
      )}
      <div aria-live="polite" className={classes.descriptionContainer}>
        <Text
          tagName="p"
          className={classNames(
            classes.description,
            expanded
              ? classes.descriptionExpanded
              : ticketMoreInfoButtonSettingEnabled
              ? classes.descriptionCollapsed
              : classes.descriptionHidden,
          )}
          data-hook={DH.DESCRIPTION}
        >
          {ticket.description}
        </Text>
      </div>
    </div>
  )
}
