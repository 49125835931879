import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import c from '../../../../classnames.scss'
import {InvoiceBreakdown, InvoiceBreakdownSize} from '../../../../invoice-breakdown'
import {TotalPriceProps} from './interfaces'
import s from './total-price.scss'

export const TotalPrice = ({soldOut}: TotalPriceProps) =>
  soldOut ? (
    <SoldOut />
  ) : (
    <InvoiceBreakdown
      containerClass={classNames(s.container, c.evTextFont, c.evTextColor)}
      size={InvoiceBreakdownSize.big}
      preliminary={true}
    />
  )

const SoldOut = () => {
  const {t} = useTranslation()

  return (
    <div className={classNames(c.evTextFont, c.evTextColor)} data-hook="event-sold-out">
      <p className={s.soldOutLabel}>{t('ticketsPicker.soldOut')}</p>
    </div>
  )
}
