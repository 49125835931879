import classNames from 'classnames'
import React from 'react'
import {PriceItemProps, PriceItemSize} from './interfaces'
import s from './price-item.scss'

export const PriceItem: React.FC<PriceItemProps> = ({
  hook,
  label,
  price,
  size = PriceItemSize.small,
}: PriceItemProps) => {
  if (!price) {
    return null
  }

  return (
    <div className={classNames(getClassName(size), s.container, s.margin)} role="text" data-hook={hook}>
      <p className={s.inlineFlex}>{label}</p>
      <p className={s.inlineFlex}>{price}</p>
    </div>
  )
}

const getClassName = (size: PriceItemSize) => {
  switch (size) {
    case PriceItemSize.extraBig:
      return s.extraBig
    case PriceItemSize.big:
      return s.big
    case PriceItemSize.medium:
      return s.medium
    case PriceItemSize.small:
    default:
      return s.small
  }
}
